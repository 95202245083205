// SASS
require('../scss/style.scss');

// JQuery
import $ from 'jquery';
import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;
// JS
import 'lightgallery';
import '../../node_modules/lightgallery/modules/lg-video';
import 'slick-carousel';
import whatInput from 'what-input'

//foundation
import './foundation-modules.js';

// Show Old IE Notice
if(!!window.ActiveXObject) {
    $("#ieNotice").removeClass("hide");
}

// Mobile Nav
$('.menu-icon a').click(function() {
    $('header nav').toggleClass("open");
    $(this).toggleClass("open");
    return false;
});

(function(context){
    let breakPoint = 1024;
    $(context+" nav ul>li>a").click(function(event, force){
        if (whatInput.ask() === 'keyboard' || whatInput.ask() === 'touch' || $(window).width() < breakPoint) {
            if($(this).parent().find("ul").length > 0 && (($(window).width() >= breakPoint && $(this).parent().hasClass('level-1')) || $(window).width() < breakPoint)) {
                if (!$(this).parent().hasClass('open')) {
                    $(this).closest("ul").find('.open').removeClass('open');
                    $(this).parent().addClass('open');
                } else {
                    $(this).parent().removeClass('open');
                }
                event.preventDefault();
            } else if (force) {
                window.location = $(this).attr('href');
            }
            event.stopPropagation();
        }
    }).keydown(function(event){
        if(whatInput.ask() === 'keyboard' && event.keyCode === 32){
            event.preventDefault();
            $(this).trigger('click', true);
        }
    });

    $(context+" .menu-icon a").keydown(function(event){
        if(whatInput.ask() === 'keyboard' && event.keyCode === 32){
            event.preventDefault();
            $(this).trigger('click', true);
        }
    });

    $("body").click(function(event){
        $(context).find('.open').removeClass('open');
    });

    if($(window).width() < breakPoint) {
        $(context+" nav ul ul .active").each(function(){
            $(this).parent().parent().addClass('open');
        });
    }

})(("header "));

if($('.slider').length) {
    $('.slider').slick({
        prevArrow:'<button type="button" data-role="none" class="button icon-left-open-big slick-prev" aria-label="Previous" tabindex="0" role="button"><span class="icon svg-icon "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"></path></svg></span></button>',
        nextArrow:'<button type="button" data-role="none" class="button icon-right-open-big slick-next" aria-label="Next" tabindex="0" role="button"><span class="icon svg-icon "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"></path></svg></span></button>',
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: false
    });
}

// Light Gallery
if($(".lg-images").length) {
    $(".lg-images").lightGallery({
        selector: 'a'
    });
}

$(document).foundation();